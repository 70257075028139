.row {
    display: flex;
    flex-wrap: wrap;
}

/* Create two equal columns that sits next to each other */
.ant-card-bordered:hover {
    border-color: #b0b0b0 !important;
    -webkit-transition: border 500ms ease-out;
    -moz-transition: border 500ms ease-out;
    -o-transition: border 500ms ease-out;
    transition: border 500ms ease-out;
}

#search ::selection {
    outline-width: 0!important;
}

@import url('https://fonts.googleapis.com/css2?family=EB+Garamond&family=Open+Sans:wght@300&family=Raleway&display=swap');

/*body {*/
/*    margin: 0;*/

/*    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',*/
/*    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',*/
/*    sans-serif;*/
/*    -webkit-font-smoothing: antialiased;*/
/*    -moz-osx-font-smoothing: grayscale;*/

/*   */
/*}*/

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.main {
    padding-top: 120px;
    margin: auto;
}
.center-screen {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 100vh;
}

.grandient {
    background: rgb(255,255,255);
    background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(223,227,247,1) 51%, rgba(255,255,255,1) 100%);
}

.page-title {
    top: 77px;
    position: fixed;
    left: 0;
    right: 0;
    background-color: #f8f8f8;
    text-align: center;
    font-family: "Open Sans Roboto", sans-serif;
    z-index: 10;
    opacity: .98;
}

.page-title-about {
    top: 77px;
    position: fixed;
    left: 0;
    right: 0;
    color: #276f86;
    background-color: #dff2ff;
    text-align: center;
    font-family: "Open Sans Roboto", sans-serif;
    z-index: 10;
    opacity: .98;
    padding: 10px;
}


#navbarScrollingDropdown {
    color: red !important;
}


#navbarScroll > div > a:nth-child(5) {
    background-color: #7b7b7b !important;
}

.logo {
    width: 68px;
}

/*for brand name*-----------------------*/
.brand {
    font-family: "EB Garamond", sans-serif;
    font-size: 280% !important;
    font-weight: lighter !important;

}

@media only screen and (max-width: 902px) {
    .brand {
        font-family: "EB Garamond", sans-serif;
        font-size: 250% !important;

    }
}

@media only screen and (max-width: 769px) {
    .brand {
        font-family: "EB Garamond", sans-serif;
        font-size: 210% !important;
    }
}

@media only screen and (max-width: 700px) {
    .brand {
        font-family: "EB Garamond", sans-serif;
        font-size: 200% !important;
    }
}

@media only screen and (max-width: 575px) {
    .brand {
        font-family: "EB Garamond", sans-serif;
        font-size: 180% !important;
    }
}

@media only screen and (max-width: 426px) {
    .brand {
        font-family: "EB Garamond", sans-serif;
        font-size: 170% !important;
    }
}

@media only screen and (max-width: 414px) {
    .brand {
        font-family: "EB Garamond", sans-serif;
        font-size: 150% !important;
    }
}

@media only screen and (max-width: 382px) {
    .brand {
        font-family: "EB Garamond", sans-serif;
        font-size: 130% !important;
    }
}

@media only screen and (max-width: 354px) {
    .brand {
        font-family: "EB Garamond", sans-serif;
        font-size: 100% !important;
    }
}


/*for brand name end *-----------------------*/

.menu-members {
    top: 63px;
    position: relative;
}

.main-members {
    margin-top: 110px;
}

@media only screen and (max-width: 800px) {
    .main-members {
        margin-top: 120px;
    }
}


@media only screen and (max-width: 460px) {
    .menu-members {
        left: 0%;
        right: 0%;
    }

}

.footerContainer {
    border-top: 1px solid #eeeeee;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: #f5f5f5;
    text-align: center;
    padding: 6px;
    font-size: small;
    color: black;
}

.spacer {
    width: 100%;
    height: 50px;
    margin-top: 150px;
}

.dropstart .dropdown-toggle::before {
    vertical-align: 0;
    display: none !important;
}

.image-container-center-screen {
    display: flex;
    justify-content: center;
    align-items: center;
}

#signIn-panel > div > form > div > button, #signUp-panel > div > form > fieldset > button {
    background-color: #062169 !important;
}

.box-shadow {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

/*.anticon {*/
/*    color: #00bcd4 !important;*/
/*}*/

.cards-hover {
    transition: all 0.1s ease-in-out;
    cursor: pointer;
    /*box-shadow: 5px 6px 6px 2px #e9ecef;*/
}


.cards-hover:hover {
    box-shadow: 2px 3px 3px 2px #e9ecef;
    /*transform: scale(1.002);*/
    /*border-color: #062169;*/
}
